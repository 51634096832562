import { FormEvent } from "react";
import "./UserForm.css";
import LOGO from "./assets/WLBLACK.png";

interface FormElements extends HTMLFormControlsCollection {
  userName: HTMLInputElement;
}

interface CustomFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const UserForm = () => {
  const onSubmit = (e: FormEvent<CustomFormElement>) => {
    e.preventDefault();
    localStorage.setItem("userName", e.currentTarget.elements.userName.value);
    const params = new URLSearchParams(window.location.search);
    params.set("userName", e.currentTarget.elements.userName.value);
    window.location.search = params.toString();
  };

  return (
    <main className="container">
      <div className="form">
        <img className="logo" src={LOGO} alt="Walter-learning Logo" />
        <form onSubmit={onSubmit}>
          <input
            type="text"
            id="userName"
            name="userName"
            required
            placeholder="Votre nom"
          />
          <button className="submitBtn" type="submit">
            Rejoindre
          </button>
        </form>
      </div>
    </main>
  );
};

export default UserForm;

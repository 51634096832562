/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import { KJUR } from "jsrsasign";
import UserForm from "./UserForm";
import LOGO from "./assets/WLBLACK.png";

const DEFAULT_DELAY = 60000;
const CLIENT_ID = "7sPSK9YdSD6g1rKjF_J4vw";
const CLIENT_SECRET = "CQOPZuximzm6LksYj4zrxKRrGPOtNu9C";
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// ZoomMtg.i18n.load("fr-FR");

function App() {
  const currentURL = new URL(window.location.href);
  const params = currentURL.searchParams;
  const sdkKey = CLIENT_ID;
  const leaveURL = "https://app.walter-learning.com/merci";
  const userName =
    params.get("userName") || localStorage.getItem("userName") || undefined;
  const userEmail = "";
  const mn = params.get("mn") || "";
  const tk = params.get("tk") || undefined;
  const pwd = params.get("pwd") || "";
  const zak = params.get("zak") || undefined;
  const start = new Date(params.get("start") || "");
  const [hasJoined, setHasJoined] = useState(false);
  const [displayHelp, setDisplayHelp] = useState(false);

  useEffect(() => {
    if (mn !== "2871480200") {
      const now = new Date();
      setTimeout(() => {
        if (!displayHelp) {
          setDisplayHelp(true);
        }
      }, Math.max(start.getTime() - now.getTime(), 0) + DEFAULT_DELAY);
    }

    if (userName) {
      getSignature();
    }
  }, []);

  useEffect(() => {
    const zoomMeetingSDK = document.getElementById("zmmtg-root");
    if (displayHelp && !hasJoined) {
      if (zoomMeetingSDK) {
        zoomMeetingSDK.style.display = "none";
      }
    } else if (userName) {
      if (zoomMeetingSDK) {
        zoomMeetingSDK.style.display = "block";
      }
    }
    if (hasJoined && mn !== "2871480200") {
      fetch("https://api.app.walter-learning.com/amphitheater/zoom_log/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tk,
        }),
      })
        .then((resp) => {
          console.log("resp", resp);
        })
        .catch((err: any) => {
          console.log("err", err);
          console.error(err);
        });
    }
  }, [displayHelp, hasJoined]);

  const getSignature = async () => {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: "HS256", typ: "JWT" };
    const meetingNumber = params.get("mn") || "";
    const zak = params.get("zak") || "";

    const oPayload = {
      appKey: CLIENT_ID,
      sdkKey: CLIENT_ID,
      mn: meetingNumber,
      role: zak ? 1 : 0,
      iat: iat,
      exp: exp,
      tokenExp: iat + 60 * 60 * 2,
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const signature = KJUR.jws.JWS.sign(
      "HS256",
      sHeader,
      sPayload,
      CLIENT_SECRET
    );
    await startMeeting(signature);
  };

  async function startMeeting(signature: string) {
    (document.getElementById("zmmtg-root") as HTMLElement).style.display =
      "block";
    if (userName && mn && sdkKey) {
      console.log("ZoomMtg.init");
      await ZoomMtg.i18n.load("fr-FR");
      ZoomMtg.init({
        defaultView: "gallery",
        // disableJoinAudio: Boolean(zak) || mn === "2871480200" ? false : true,
        leaveUrl: leaveURL,
        patchJsMedia: true,
        success: () => {
          console.log("######### SUCCESS 1 #########");
          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: mn,
            passWord: pwd,
            userName: userName,
            userEmail: userEmail,
            tk: tk,
            zak: zak,
            success: () => {
              console.log("######### SUCCESS Z #########");
              setHasJoined(true);
            },
            error: (error: any) => {
              console.log(error);
            },
          });
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    }
  }

  if (!userName) {
    return <UserForm />;
  } else {
    return (
      <div className="App">
        {displayHelp && !hasJoined && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="logo" src={LOGO} alt="Walter-learning Logo" />
            <div
              style={{
                minWidth: "200px",
                maxWidth: "350px",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "10px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                En cas de difficultés pour se connecter veuillez contacter le
                support au <br />
                <a
                  style={{ fontWeight: "bold" }}
                  href={"tel:+33 07 55 50 35 32"}
                >
                  07 55 50 35 32
                </a>
              </p>
              <button
                className={"btn"}
                onClick={() => {
                  setDisplayHelp(false);
                }}
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
